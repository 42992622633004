import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customerReducer from "../features/customers/customerslice";
import bCategoryReducer from "../features/bCategory/bcategorySlice";
import blogReducer from "../features/blogs/blogSlice";
import uploadReducer from "../features/upload/uploadSlice";
import enquiryReducer from "../features/enquiry/enquirySlice";
import galleryReducer from '../features/gallery/gallerySlice'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    bCategory: bCategoryReducer,
    blogs: blogReducer,
    upload: uploadReducer,
    enquiry: enquiryReducer,
    gallery:galleryReducer
  },
});
