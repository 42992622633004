import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import MainLayout from "./components/MainLayout";
import Forgetpassword from "./pages/Forgetpassword";
import Resetpassword from "./pages/Resetpassword";

import Bloglist from "./pages/Bloglist";
import Blogcatlist from "./pages/Blogcatlist";
import Addblog from "./pages/Addblog";
import Addblogcat from "./pages/Addblogcat";
import Enquiries from "./pages/Enquiries";
import ViewEnq from "./pages/ViewEnq";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { OpenRoutes } from "./routing/OpenRoutes";
import AddGallery from "./pages/addgallery";
import Register from "./pages/Signup";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoutes>
              <Login />
            </OpenRoutes>
          }
        />
     
        <Route
          path="/admin"
          element={
            <PrivateRoutes>
              <MainLayout />
            </PrivateRoutes>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="signup" element={<Register />} />

          <Route path="enquiries/:id" element={<ViewEnq />} />
          {/* <Route path="blog-list" element={<Bloglist />} /> */}
          <Route path="gallery" element={<AddGallery />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />

        </Route>
      </Routes>
    </Router>
  );
}

export default App;
