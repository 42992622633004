import React, { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAEnquiry,
  getEnquiries,
  resetState,
  updateAEnquiry,
} from "../features/enquiry/enquirySlice";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import moment from "moment";

const Enquiries = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [enqId, setEnqId] = useState("");
  const showModal = (id) => {
    setOpen(true);
    setEnqId(id);
  };
  const hideModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(resetState());
    dispatch(getEnquiries());
  }, [dispatch]);

  const enqState = useSelector((state) => [...state.enquiry.enquiries]);

  // Sort enquiries by date in descending order
  const sortedEnquiries = [...enqState].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const handleReminderChange = (date, key) => {
    // Here, you can dispatch an action to update the reminder date in the state
    // You can access the specific enquiry using its key
    console.log(`Setting reminder for record ${key} to ${date ? date.format('YYYY-MM-DD') : null}`);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "EnquiryDate",
      dataIndex: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Problem",
      dataIndex: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Time Elapsed",
      dataIndex: "elapsedTime",
    },
    {
      title: "Reminder",
      dataIndex: "reminderDate",
      render: (_, record) => (
        <DatePicker
          value={record.reminderDate ? moment(record.reminderDate, 'YYYY-MM-DD') : null}
          format='YYYY-MM-DD'
          onChange={(date) => handleReminderChange(date, record.key)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const data = sortedEnquiries.map((enq, index) => {
    let truncatedMessage = enq.message.length > 40 ? enq.message.substring(0, 30) + "..." : enq.message;

    // Format date to show only YYYY-MM-DD
    const formattedDate = new Date(enq.createdAt).toISOString().split('T')[0];

    // Calculate time elapsed since enquiry date
    const enquiryDate = new Date(enq.createdAt);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - enquiryDate.getTime();
    const daysElapsed = Math.floor(timeDifference / (1000 * 3600 * 24));
    const elapsedTime = daysElapsed > 0 ? `${daysElapsed} days ago` : "Today";

    // Format reminder date to show only YYYY-MM-DD
    const formattedReminderDate = enq.reminderDate ? new Date(enq.reminderDate).toISOString().split('T')[0] : "";

    // Check if reminder date matches current date
    const isReminderToday = formattedReminderDate === formattedDate;

    return {
      key: index + 1,
      name: enq.name,
      address: enq.address,
      mobile: enq.phone,
      message: truncatedMessage,
      date: formattedDate,
      status: (
        <span
          style={{
            color:
              enq.status === "Notseen"
                ? "red"
                : enq.status === "Resolved"
                ? "green"
                : enq.status === "InProgress"
                ? "yellow"
                : enq.status === "Contacted"
                ? "blue"
                : "inherit", // fallback color
            fontSize: "16px", // adjust font size as needed
            fontWeight: "bold", // make text bold
            display: "inline-block", // ensure box width fits content
            padding: "4px 8px", // add padding for spacing
            borderRadius: "4px", // add rounded corners
          }}
        >
          {enq.status}
        </span>
      ),
      elapsedTime: elapsedTime,
      reminderDate: isReminderToday ? <span style={{ color: "red" }}>{formattedReminderDate}</span> : formattedReminderDate,
      action: (
        <>
          <Link
            className="ms-3 fs-3 text-danger"
            to={`/admin/enquiries/${enq.id}`}
          >
            <AiOutlineEye />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(enq.id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    };
  });

  const setEnquiryStatus = (value, id) => {
    dispatch(updateAEnquiry({ id, status: value }));
  };

  const deleteEnquiry = (id) => {
    dispatch(deleteAEnquiry(id));
    setOpen(false);
  };

  return (
    <div>
      <h3 className="mb-4 title">Enquiries</h3>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteEnquiry(enqId);
        }}
        title="Are you sure you want to delete this enquiry?"
      />
    </div>
  );
};

export default Enquiries;
