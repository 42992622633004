import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import galleryService from "./galleryService";
//Gallerys
//Gallery
//gallerys
export const getAllGallery = createAsyncThunk("gallery/get-gallerys", async (thunkAPI) => {
  try {
    return await galleryService.getAllGallery();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createGallery = createAsyncThunk(
  "gallery/create-gallerys",
  async (galleryData, thunkAPI) => {
    try {
      return await galleryService.createGallery(galleryData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getAGallery = createAsyncThunk(
  "gallery/get-gallery",
  async (id, thunkAPI) => {
    try {
      return await galleryService.getGallery(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAGallery = createAsyncThunk(
  "gallery/update-gallery",
  async (brand, thunkAPI) => {
    try {
      return await galleryService.updateGallery(brand);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAGallery = createAsyncThunk(
  "gallery/delete-gallery",
  async (id, thunkAPI) => {
    try {
      return await galleryService.deleteGallery(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetState = createAction("Reset_all");
const initialState = {
  blogs: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};


export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {},
  
  extraReducers: (builder) => {
    builder
      .addCase(getAllGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.blogs = action.payload;
      })
      .addCase(getAllGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdBlog = action.payload;
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.blogName = action.payload.title;
        state.blogDesc = action.payload.description;
        state.blogCategory = action.payload.cat;
        state.blogImages = action.payload.images;
        state.blogurl = action.payload.url;
        state.blogKeybord = action.payload.keywords;
        state.blogMetaDes = action.payload.meta_description;
      })
      .addCase(getAGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedBlog = action.payload;
      })
      .addCase(updateAGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedBlog = action.payload;
      })
      .addCase(deleteAGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});
export default gallerySlice.reducer;
