import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  createGallery,
  getAGallery,
  resetState,
  updateAGallery,
} from "../features/gallery/gallerySlice";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  keywords: yup.string().required("keywords is Required"),
});

const AddGallery = () => {
  // const img = useSelector((state) => state.upload.images);
  // console.log("imgState:", img);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getBlogId = location.pathname.split("/")[3];
  const imgState = useSelector((state) => state.upload.images);
  const blogState = useSelector((state) => state.blogs);

  const {
    isSuccess,
    isError,
    isLoading,
    createdBlog,
    blogName,
    blogKeybord,
    blogImages,
    updatedBlog,
  } = blogState;

  useEffect(() => {
    if (getBlogId !== undefined) {
      dispatch(getAGallery(getBlogId));
    } else {
      dispatch(resetState());
    }
  }, [getBlogId]);

  useEffect(() => {
    if (isSuccess && createdBlog) {
      toast.success("Blog Added Successfully!");
    }
    if (isSuccess && updatedBlog) {
      toast.success("Blog Updated Successfully!");
      navigate("/admin/blog-list");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const imagesArray = JSON.parse(blogImages || "[]");

  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  console.log("imgState:", img);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: blogName || "",
      alt: blogKeybord || "",
      images: img || [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getBlogId !== undefined) {
        const data = { id: getBlogId, blogData: values };
        dispatch(updateAGallery(data));
        dispatch(resetState());
      } else {
        dispatch(createGallery(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getBlogId !== undefined ? "Edit" : "Add"} Blog
      </h3>

      <div className="">
        <form action="" onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog Title"
              name="title"
              onChng={formik.handleChange("title")}
              onBlr={formik.handleBlur("title")}
              val={formik.values.title}
            />
          </div>
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>

         
          <label
            style={{
              fontSize: "20px",
              color: "black",
              paddingTop: "20px",
              textAlign: "center",
              margin: "20px",
            }}
          >
            Upload Some image
          </label>

          <div className="bg-white border-1 p-5 text-center mt-3">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap mt-3 gap-3">
            {imgState?.map((i, j) => (
              <div className="position-relative" key={j}>
                <button
                  type="button"
                  onClick={() => dispatch(delImg(i.public_id))}
                  className="btn-close position-absolute"
                  style={{ top: "10px", right: "10px" }}
                ></button>
                <img src={i.url} alt="" width={200} height={200} />
              </div>
            ))}
          </div>

          {getBlogId !== undefined && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {imagesArray.map(
                (image, index) =>
                  index % 3 === 0 && ( // Display only if index is divisible by 3 (0, 3, 6, ...)
                    <div
                      key={index}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {imagesArray.slice(index, index + 3).map((img, idx) => (
                        <div
                          key={idx}
                          style={{
                            flex: 1,
                            marginRight: "10px",
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {/* Adjust margin as needed */}
                          <img
                            src={img.url}
                            alt={`Uploaded ${img.public_id}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              alignSelf: "center",
                            }}
                          />{" "}
                          {/* Adjust width and height as needed */}
                        </div>
                      ))}
                    </div>
                  )
              )}
            </div>
          )}

          <h3 className="mb-4 title" style={{ textAlign: "center" }}>
            seo part
          </h3>
          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog Keywords"
              name="keywords"
              onChng={formik.handleChange("keywords")}
              onBlr={formik.handleBlur("keywords")}
              val={formik.values.keywords}
            />
          </div>
          <div className="error">
            {formik.touched.title && formik.errors.keywords}
          </div>
          {/* <div style={{ width: "100%" }}> */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-success border-0 rounded-3 my-5"
              type="submit"
            >
              {getBlogId !== undefined ? "Edit" : "Add"} Blog
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGallery;
