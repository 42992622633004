import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const getGallerys = async () => {
  const response = await axios.get(`${base_url}gallery/`);


  return response.data;
};
const createGallery = async (gallery) => {
  const response = await axios.post(`${base_url}gallery/`, gallery, config);
  return response.data;
};
const updateGallery = async (gallery) => {
  const response = await axios.put(
    `${base_url}gallery/${gallery.id}`,
    {
      title: gallery.galleryData.title,
      alt: gallery.galleryData.alt,
      images: gallery.galleryData.images,

    },
    config
  );

  return response.data;
};
const getGallery = async (id) => {
  const response = await axios.get(`${base_url}gallery/${id}`, config);

  return response.data;
};

const deleteGallery = async (id) => {
  const response = await axios.delete(`${base_url}gallery/${id}`, config);

  return response.data;
};
const blogService = {
  getGallery,
  createGallery,
  getGallerys,
  updateGallery,
  deleteGallery,
};

export default blogService;
