import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  createBlogs,
  getABlog,
  resetState,
  updateABlog,
} from "../features/blogs/blogSlice";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  url: yup.string().required("url is Required"),
  cat: yup.string().required("catagory is Required"),
  keywords: yup.string().required("keywords is Required"),
  meta_description: yup.string().required("meta_description is Required"),
});

const Addblog = () => {
  // const img = useSelector((state) => state.upload.images);
  // console.log("imgState:", img);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getBlogId = location.pathname.split("/")[3];
  const imgState = useSelector((state) => state.upload.images);
  const blogState = useSelector((state) => state.blogs);

  const {
    isSuccess,
    isError,
    isLoading,
    createdBlog,
    blogName,
    blogurl,
    blogKeybord,
    blogMetaDes,
    seotitle,
    blogCat,
    blogDesc,
    blogImages,
    updatedBlog,
  } = blogState;

  useEffect(() => {
    if (getBlogId !== undefined) {
      dispatch(getABlog(getBlogId));
    } else {
      dispatch(resetState());
    }
  }, [getBlogId]);

  useEffect(() => {
    if (isSuccess && createdBlog) {
      toast.success("Blog Added Successfully!");
    }
    if (isSuccess && updatedBlog) {
      toast.success("Blog Updated Successfully!");
      navigate("/admin/blog-list");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const imagesArray = JSON.parse(blogImages || "[]");

  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  console.log("imgState:", img);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: blogName || "",
      url: blogurl || "",
      meta_description: blogMetaDes || "",
      keywords: blogKeybord || "",
      cat: blogCat || "",
      description: blogDesc || "",
      images: img || [],
      seotitle:seotitle || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getBlogId !== undefined) {
        const data = { id: getBlogId, blogData: values };
        dispatch(updateABlog(data));
        dispatch(resetState());
      } else {
        dispatch(createBlogs(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getBlogId !== undefined ? "Edit" : "Add"} Blog
      </h3>

      <div className="">
        <form action="" onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog Title"
              name="title"
              onChng={formik.handleChange("title")}
              onBlr={formik.handleBlur("title")}
              val={formik.values.title}
            />
          </div>
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          {/* blogurl */}
     
          {/*  blogCat */}
          <select
            name="cat"
            onChange={formik.handleChange("cat")}
            onBlur={formik.handleBlur("cat")}
            value={formik.values.cat}
            className="form-control py-3 mt-3"
            id=""
          >
            <option value="">Select Blog catagory</option>
            {[
              "plumber",
              "construction",
              "interior design",
              "safehouse",
              "painting",
              "renovation",
              "maintainance",
              "Remodining",
              "construction News",
              "other",
            ].map((cat, index) => (
              <option key={index} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          <div className="error">{formik.touched.cat && formik.errors.cat}</div>

          <ReactQuill
            theme="snow"
            className="mt-3"
            name="description"
            onChange={formik.handleChange("description")}
            value={formik.values.description}
            style={{ height: "500px", paddingBottom: "40px" }} // Set the height inline
          />
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
          <label
            style={{
              fontSize: "20px",
              color: "black",
              paddingTop: "20px",
              textAlign: "center",
              margin: "20px",
            }}
          >
            Upload Some image
          </label>


          <div className="bg-white border-1 p-5 text-center mt-3">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap mt-3 gap-3">
            {imgState?.map((i, j) => (
              <div className="position-relative" key={j}>
                <button
                  type="button"
                  onClick={() => dispatch(delImg(i.public_id))}
                  className="btn-close position-absolute"
                  style={{ top: "10px", right: "10px" }}
                ></button>
                <img src={i.url} alt="" width={200} height={200} />
              </div>
            ))}
          </div>

          {getBlogId !== undefined && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {imagesArray.map(
                (image, index) =>
                  index % 3 === 0 && ( // Display only if index is divisible by 3 (0, 3, 6, ...)
                    <div
                      key={index}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {imagesArray.slice(index, index + 3).map((img, idx) => (
                        <div
                          key={idx}
                          style={{
                            flex: 1,
                            marginRight: "10px",
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          >
                          {" "}
                          {/* Adjust margin as needed */}
                          <img
                            src={img.url}
                            alt={`Uploaded ${img.public_id}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              alignSelf: "center",
                            }}
                          />{" "}
                          {/* Adjust width and height as needed */}
                        </div>
                      ))}
                    </div>
                  )
              )}
            </div>
          )}

          <h3 className="mb-4 title" style={{ textAlign: "center" }}>
            seo part
          </h3>
          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog Keywords"
              name="keywords"
              onChng={formik.handleChange("keywords")}
              onBlr={formik.handleBlur("keywords")}
              val={formik.values.keywords}
            />
          </div>
          <div className="error">
            {formik.touched.keywords && formik.errors.keywords}
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog url"
              name="url"
              onChng={formik.handleChange("url")}
              onBlr={formik.handleBlur("url")}
              val={formik.values.url}
            />
          </div>
          <div className="error">
            {formik.touched.url && formik.errors.url}
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Blog Seo Title"
              name="seotitle"
              onChng={formik.handleChange("seotitle")}
              onBlr={formik.handleBlur("seotitle")}
              val={formik.values.seotitle}
            />
          </div>
          <div className="error">
            {formik.touched.seotitle && formik.errors.seotitle}
          </div>
          {/* <div style={{ width: "100%" }}> */}
          <div className="mt-4">
            <label htmlFor="meta_description" style={{ fontSize: "18px" }}>
              Enter Blog meta_description
            </label>
            <textarea
              className="mt-4"
              style={{
                width: "100%",
                boxSizing: "border-box",
                minHeight: "60px",
              }}
              type="text"
              id="meta_description"
              name="meta_description"
              onChange={formik.handleChange("meta_description")}
              onBlur={formik.handleBlur("meta_description")}
              value={formik.values.meta_description}
            />
          </div>
          <div className="error">
            {formik.touched.meta_description && formik.errors.meta_description}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="btn btn-success border-0 rounded-3 my-5"
              type="submit"
            >
              {getBlogId !== undefined ? "Edit" : "Add"} Blog
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addblog;
